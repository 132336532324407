
import { defineComponent } from '@vue/composition-api'
import { db } from '@/plugins/firebase'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import store from '@/store'
import { functions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import SnackBar from '@/components/Dashboard/PageViewItems/SnackbarComponent.vue'
import LoadingCircle from '@/components/Dashboard/PageViewItems/LoadingCircleComponent.vue'

type CampaignType = {
  documentId: string
  customerCode: string
  customerId: string
  campaignId: string
  campaignCode: string
  campaignName: string
  isDeleted: boolean
  isUnlimited: boolean
  limits: number
}

type TicketInfoType = {
  documentId: string
  confirmedAt: string
  countIsUndefined: boolean
  count: number
  couponCode: string
  isAvailable: boolean
  password: string
  punchedAt: string
}

type SearchedCouponsType = {
  id: number
  learnerId: string
  campaignId: string
  campaignCode: string
  campaignName: string
  ticket: TicketInfoType
  isDeleted: boolean
  isUnlimited: boolean
  limits: string
  couponCode: string | null
  learnerMail: string
  learnerName: string
  cefrScore: number
  cefrLevel: string
}

type ResultType = {
  userInfo: {
    uid: string
    customerId: string | null
    customerCode: string | null
    campaignId: string | null
    campaignCode: string | null
    campaignName: string | null
    couponCode: string | null
    password: string | null
    email: string | null
    language: 'en' | 'ja'
  }
  dataURL: string
  dialogStatus: {
    dialogId: string
    dialogStartedAt: string
    dialogEndedAt: string
    assessmentCompletedAt: string
    dialogType: string
    isAssessed: boolean
    isInterviewed: boolean
    scenarioId: string
  }
  fastResult: {
    feedback: {
      strength: string
      weakness: string
    }
    levelDescription: string
    score: {
      cefrOverall: {
        cefrLevel: string
        score: number
        description: string
      }
      accuracy: {
        cefrLevel: string
        score: number
        description: string
      }
      coherence: {
        cefrLevel: string
        score: number
        description: string
      }
      fluency: {
        cefrLevel: string
        score: number
        description: string
      }
      interaction: {
        cefrLevel: string
        score: number
        description: string
      }
      phonology: {
        cefrLevel: string
        score: number
        description: string
      }
      range: {
        cefrLevel: string
        score: number
        description: string
      }
    }
  }
  systemInfo: {
    [moduleName: string]: {
      version: string
    }
  }
}

export default defineComponent({
  name: 'LearnersView',
  components: {
    SnackBar,
    LoadingCircle,
  },
  data() {
    return {
      isFilterTest: true,
      couponProgressValue: 0,

      filterCouponCode: '',
      dialogConfirm: false,

      editingIndex: -1,
      editeingItem: {} as SearchedCouponsType,
      campaigns: [] as CampaignType[],
      searchedCoupons: [] as SearchedCouponsType[],

      isSnackbarAction: false,
      snackbarText: this.$t(
        'interviewDataSearch.learnerDataUpdatedMessage'
      ) as string,
      snackbarColor: 'pink',

      isLoading: false,
      loadingText: this.$t(
        'interviewDataSearch.progress.longOperationWaitMessage'
      ) as string,
    }
  },

  async mounted() {
    // this.readData()
    this.getCampaigns()
  },

  methods: {
    // snackbarコンポーネントのcloseSnackbar関数
    closeSnackbar() {
      this.isSnackbarAction = false
    },

    async getCampaigns() {
      // customerIdと一致するcampaignsを取得
      const customerId = store.state.customerId
      const campaignsRef = collection(db, 'campaigns')

      getDocs(query(campaignsRef, where('customerId', '==', customerId))).then(
        (campaignData) => {
          campaignData.forEach(async (doc) => {
            this.campaigns.push({
              documentId: doc.id,
              customerId: doc.data().customerId,
              customerCode: doc.data().customerCode,
              campaignId: doc.id,
              campaignCode: doc.data().campaignCode,
              campaignName: doc.data().name,
              isDeleted: doc.data().isDeleted,
              isUnlimited: doc.data().isUnlimited,
              limits: doc.data().limits,
            })
          })
        }
      )
    },

    // 検索ボタンを押した際の処理
    async searchCoupon(filterCouponCode: string) {
      this.isLoading = true
      this.searchedCoupons = []

      // ローディングフェイズ1
      this.couponProgressValue = 0
      this.loadingText = this.$t(
        'interviewDataSearch.progress.fetchingDataMessage'
      ) as string

      const results = (await this.getResultsByCouponCode(
        filterCouponCode
      )) as ResultType[]

      // ローディングフェイズ2
      this.loadingText = this.$t(
        'interviewDataSearch.progress.displayingSearchResultsMessage'
      ) as string

      // learnersからlearnerMail・learnerNameを取得
      let learnerMail = ''
      let learnerName = ''
      if (results === undefined || results.length === 0) {
        // console.log(filterCouponCode, 'はまだ受験結果がありません。')
      } else {
        const learnerId = results[0].userInfo.uid
        const learnerSnap = await getDoc(doc(db, 'learners', learnerId))
        if (learnerSnap.exists()) {
          learnerMail = learnerSnap.data().email
          learnerName = learnerSnap.data().displayName
        }

        const resultNum = results.length
        for (let i = 0; i < resultNum; i++) {
          const result = results[i]

          // this.campaigns内でcampaignId検索
          const campaignData = this.campaigns.filter(
            (item) => item.campaignId === result.userInfo.campaignId
          )[0]

          if (campaignData === undefined || campaignData.isDeleted === true) {
            continue
          }

          let campaignLimit = ''
          if (campaignData.isUnlimited === undefined) {
            campaignLimit = '-'
          } else if (campaignData.isUnlimited) {
            campaignLimit = '無制限'
          } else {
            campaignLimit = String(campaignData.limits)
          }

          // ticketのcouponCodeとcountを取得
          const campaignRef = doc(db, 'campaigns', campaignData.documentId)
          let ticketInfos = [] as TicketInfoType[]
          await getDocs(
            query(
              collection(db, 'learners', learnerId, 'tickets'),
              where('campaign', '==', campaignRef)
            )
          ).then((ticketData) => {
            ticketData.forEach((doc) => {
              let countIsUndefined: boolean
              let countTransport: number
              if (doc.data().customerOptions.count === undefined) {
                countIsUndefined = true
                countTransport = 0
              } else {
                countIsUndefined = false
                countTransport = doc.data().customerOptions.count
              }

              if (doc.data().customerOptions.couponCode === filterCouponCode) {
                ticketInfos.push({
                  documentId: doc.id,
                  confirmedAt: doc.data().customerOptions.confirmedAt,
                  countIsUndefined: countIsUndefined,
                  count: countTransport,
                  couponCode: doc.data().customerOptions.couponCode,
                  isAvailable: doc.data().customerOptions.isAvailable,
                  password: doc.data().customerOptions.password,
                  punchedAt: doc.data().customerOptions.punchedAt,
                })
              }
            })
          })

          if (ticketInfos.length === 0) {
            continue
          }

          this.searchedCoupons.push({
            id: i,
            learnerId: learnerId,
            campaignId: campaignData.campaignId,
            campaignCode: campaignData.campaignCode,
            campaignName: campaignData.campaignName,
            ticket: ticketInfos[0],
            isDeleted: campaignData.isDeleted,
            isUnlimited: campaignData.isUnlimited,
            limits: campaignLimit,
            couponCode: result.userInfo.couponCode,
            learnerMail: learnerMail,
            learnerName: learnerName,
            cefrScore: result.fastResult.score.cefrOverall.score,
            cefrLevel: result.fastResult.score.cefrOverall.cefrLevel,
          })
          this.couponProgressValue = Math.floor((i / resultNum) * 100) // プログレスバーの処理（もしかしたら、continueがあるif文の前の方がいいかも）
        }
      }
      // プログレスバーの初期化
      this.loadingText = this.$t(
        'interviewDataSearch.progress.longOperationWaitMessage'
      ) as string
      this.couponProgressValue = 0
      this.isLoading = false
    },

    async getResultsByCouponCode(couponCode: string) {
      const getResultsByCouponCodeForManagers = httpsCallable(
        functions,
        'getResultsByCouponCodeForManagers'
      )
      const inputData = {
        couponCode: couponCode,
      }
      // let uids = [] as string[]
      return await getResultsByCouponCodeForManagers(inputData)
        .then((result) => {
          const data = result.data
          return data
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },

    // 再受験ボタン
    resumeBtn(resumeCoupon: SearchedCouponsType) {
      this.dialogConfirm = !this.dialogConfirm
      this.editingIndex = this.searchedCoupons.indexOf(resumeCoupon)
      this.editeingItem = resumeCoupon
    },

    // 再受験機能
    async resume() {
      this.dialogConfirm = !this.dialogConfirm
      const learnerId = this.editeingItem.learnerId
      const ticket = this.editeingItem.ticket
      await updateDoc(
        doc(db, 'learners', learnerId, 'tickets', ticket.documentId),
        {
          customerOptions: {
            confirmedAt: ticket.confirmedAt,
            count: ticket.count - 1,
            couponCode: ticket.couponCode,
            isAvailable: true,
            password: ticket.password,
            punchedAt: ticket.punchedAt,
          },
        }
      )
      this.searchCoupon(ticket.couponCode)
      this.isSnackbarAction = true
      this.snackbarText = this.$t(
        'interviewDataSearch.retestConfigurationCompletedMessage'
      ) as string
      this.snackbarColor = 'pink'
    },
  },
})
