
import { defineComponent } from '@vue/composition-api'
import { auth } from '@/plugins/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { getAuth } from '@firebase/auth'

export default defineComponent({
  name: 'SecurityPass',
  setup() {
    const auth = getAuth()
    const user = auth.currentUser
    let email = ''
    if (user && user.email) {
      email = user.email
    }
    return {
      email,
    }
  },
  data() {
    return {
      password: '',
      showPassword: false,
    }
  },
  methods: {
    onClick() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        //.then((user) => {
        .then(() => {
          //  console.log('認証に成功しました: ', user.user.uid)
          this.$router.push({
            name: 'Security',
          })
        })
        .catch((error) => {
          console.log('error code:', error.code)
          // サインイン失敗時の処理追加必要
        })
    },
  },
})
