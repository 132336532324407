
import { defineComponent } from '@vue/composition-api'
import SetSchedule from '@/components/Dashboard/PageViewItems/SetScheduleComponent.vue'
import LoadingCircle from '@/components/Dashboard/PageViewItems/LoadingCircleComponent.vue'
import moment from 'moment-timezone'
import { db } from '@/plugins/firebase'
import store from '@/store'
import { v4 } from 'uuid'
import { ScenarioSelectItem, Scenario } from '@/resources/scenario'
import { AgentGroup } from '@/resources/agentGroup'
import { Customer } from '@/resources/customer'
import { TranslateResult } from 'vue-i18n'

// データの読み取り用モジュール
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  writeBatch,
  query,
  where,
} from 'firebase/firestore'

type CouponType = {
  id: number
  couponCode: string | null
  password: string | null
}

type NewStudentType = {
  email: string | null
  displayName: string | null
}

type agentsType = {
  agentId: string
  agentName: string
}

type AgentGroupMap = { [agentGroupId in string]: AgentGroup }
type ScenarioMap = { [scenarioId in string]: Scenario }

const br = /\r\n|\n|\r/g

export default defineComponent({
  name: 'SetExam',

  components: {
    SetSchedule,
    LoadingCircle,
  },

  props: {
    isModify: { type: Boolean, default: false },
    campaignId: { type: String, default: '' },
    campaignCode: { type: String, default: '' },
    campaignName: { type: String, default: '' },
    isUnlimited: { type: Boolean, default: false },
    limits: { type: String, default: '' },
    couponNum: { type: Number, default: 0 },
    openTime: {
      type: Number,
      default: new Date(moment().tz('Asia/Tokyo').format()).getTime(),
    },
    closedTime: {
      type: Number,
      default: new Date(moment().tz('Asia/Tokyo').format()).getTime(),
    },
    startTime: {
      type: Number,
      default: new Date(moment().tz('Asia/Tokyo').format()).getTime(),
    },
    endTime: {
      type: Number,
      default: new Date(moment().tz('Asia/Tokyo').format()).getTime(),
    },
    scenarioDocId: {
      type: String,
      default: '',
    },
    isDynamicCampaign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formInput: {
        scenarioSelectItem: null as ScenarioSelectItem | null,
      },
      dialogConfirm: false,
      dialogDelete: false,
      isLoading: false,
      couponProgressValue: 0,

      fileName: '',
      isSettingDisabled: true,
      coupons: [] as CouponType[],
      duplicatedStudents: [] as NewStudentType[],
      newRegisterStudents: [] as NewStudentType[],
      agents: [] as agentsType[],
      agentName: '',
      campaignIdField: '',
      campaignCodeField: '',
      campaignNameField: '',
      registeredCoupons: [] as string[],
      typeLimited: '',
      isUnlimitedField: false,
      limitList: [] as string[],
      limitsField: '',
      openTimeField: new Date(moment().tz('Asia/Tokyo').format()).getTime(),
      closedTimeField: new Date(moment().tz('Asia/Tokyo').format()).getTime(),
      startTimeField: new Date(moment().tz('Asia/Tokyo').format()).getTime(),
      endTimeField: new Date(moment().tz('Asia/Tokyo').format()).getTime(),
      isMatch: false,

      limit: 0,
      couponCount: 0,

      // customerId: '' || null,
      customerCode: '',

      fileErrorMessage: '',

      // collection scenariosから取得用
      scenarioMap: {} as ScenarioMap,

      // collection agentGroupsから取得用
      agentGroupMap: {} as AgentGroupMap,

      isCampaignCodeError: false,

      allowedScenarioDocIds: [] as string[],

      isDynamic: false,
    }
  },

  async created() {
    this.initialize()
  },

  async mounted() {
    this.scenarioMap = await this.getScenarioMap()
    this.agentGroupMap = await this.getAgentGroupMap()
    this.allowedScenarioDocIds =
      await this.getAllowedScenarioDocIdsForCustomer()
    this.limitList = this.fetchLimitList()
  },

  watch: {
    formInput: {
      deep: true,
      handler(val) {
        console.log('formInput:', val)
      },
    },
    async campaignId(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.resetIsdynamic()
      }
      this.formInput.scenarioSelectItem = this.makeScenarioSelectItem(
        this.scenarioDocId
      )
      this.campaignIdField = newValue
      this.getRegisteredCoupons()
    },
    campaignCode(newValue) {
      this.campaignCodeField = newValue
    },
    campaignName(newValue) {
      this.campaignNameField = newValue
    },
    typeLimited(newValue) {
      if (
        newValue === this.$t('campaignSetting.interviewCountOption.unlimited')
      ) {
        this.isUnlimitedField = true
      } else if (
        newValue ===
        this.$t('campaignSetting.interviewCountOption.specifyNumber')
      ) {
        this.isUnlimitedField = false
      }
    },
    isUnlimited(newValue) {
      this.isUnlimitedField = newValue
    },
    limits(newValue) {
      this.limitsField = newValue
      // propsの受け渡し順的に、limitsの後にisunlimitedの変化を記述
      if (this.isUnlimitedField) {
        this.typeLimited = this.$t(
          'campaignSetting.interviewCountOption.unlimited'
        ) as string
      } else {
        this.typeLimited = this.$t(
          'campaignSetting.interviewCountOption.specifyNumber'
        ) as string
      }
    },
    couponNum(newValue) {
      this.couponCount = newValue
    },
    openTime(newValue) {
      this.openTimeField = newValue
    },
    closedTime(newValue) {
      this.closedTimeField = newValue
    },
    startTime(newValue) {
      this.startTimeField = newValue
    },
    endTime(newValue) {
      this.endTimeField = newValue
    },
    openTimeField(newValue) {
      if (this.isMatch) {
        this.startTimeField = newValue
      }
    },
    closedTimeField(newValue) {
      if (this.isMatch) {
        this.endTimeField = newValue
      }
    },
    isMatch(newValue) {
      if (newValue) {
        // マッチさせる場合の処理
        this.startTimeField = this.openTimeField
        this.endTimeField = this.closedTimeField
      }
    },
    scenarioDocId(val) {
      if (this.isDynamic) {
        this.formInput.scenarioSelectItem = null
      } else {
        this.formInput.scenarioSelectItem = this.makeScenarioSelectItem(val)
      }
    },
    isDynamic(newValue) {
      if (newValue) {
        this.formInput.scenarioSelectItem = null
      }
    },
    isDynamicCampaign(newVal) {
      this.isDynamic = newVal
    },
  },

  computed: {
    // 未入力がある場合はボタンを押せないようにする処理
    inputed() {
      let isDisabled = false
      // キャンペーンコードが未入力だった場合
      if (this.campaignCodeField.length === 0) {
        isDisabled = true
      }
      // キャンペーンコードが未入力だった場合
      if (this.campaignNameField.length === 0) {
        isDisabled = true
      }
      // ファイル名が未入力だった場合
      if (this.fileName.length === 0) {
        if (!this.isModify) {
          isDisabled = true
        }
      }
      // 受験回数が未入力だった場合
      if (this.typeLimited.length === 0) {
        isDisabled = true
      }
      // 受験回数が、「制限回数を設定する」と「無制限」以外だった場合
      if (!this.limitList.includes(this.typeLimited)) {
        isDisabled = true
      }
      // 受験回数が「制限回数を設定する」かつ回数が未入力だった場合
      if (!this.isUnlimitedField && this.limitsField.length === 0) {
        isDisabled = true
      }
      // 受験回数が「制限回数を設定する」かつ回数が英数字じゃなかった場合
      if (!this.isUnlimitedField && !/^[0-9]/.test(this.limitsField)) {
        isDisabled = true
      }
      // クローズの時刻より公開時刻の方が遅かった場合
      if (this.closedTimeField <= this.openTimeField) {
        isDisabled = true
      }
      // 終了時刻より開始時刻の方が遅かった場合
      if (this.endTimeField <= this.startTimeField) {
        isDisabled = true
      }

      // シナリオプルダウンの値がdefaultかstringの場合
      if (
        (!this.isDynamic && this.formInput.scenarioSelectItem === null) ||
        typeof this.formInput.scenarioSelectItem === 'string'
      ) {
        isDisabled = true
      }

      // キャンペーンコードが重複している場合
      if (this.isCampaignCodeError) {
        isDisabled = true
      }

      return isDisabled
    },
    rules() {
      return {
        required: (value: string) =>
          !!value || this.$t('campaignSetting.variation.required'),
        halfSize: (value: string) =>
          /^[a-zA-Z0-9_-]*$/.test(value) ||
          this.$t(
            'campaignSetting.variation.inputAlphanumericHyphenUnderscore'
          ),
        number: (value: string) =>
          /^[0-9]*$/.test(value) ||
          this.$t('campaignSetting.variation.inputHalfWidthNumber'),
        inLimitList: (value: string) =>
          this.limitList.includes(value) ||
          this.$t('campaignSetting.variation.chooseFromOptions'),
        validSelection: (value: ScenarioSelectItem) => {
          if (!value)
            return this.$t('campaignSetting.variation.chooseFromOptions')
          const isItemInList = this.scenarioSelectItems.some(
            (item) => item.label === value.label
          )
          return (
            isItemInList ||
            this.$t('campaignSetting.variation.chooseFromOptions')
          )
        },
      }
    },
    scenarioSelectItems(): ScenarioSelectItem[] {
      return this.allowedScenarioDocIds
        .map((docId) => this.makeScenarioSelectItem(docId))
        .filter((item) => item !== null) as ScenarioSelectItem[]
    },
  },

  methods: {
    // propsの引き渡し
    initialize() {
      this.campaignIdField = this.campaignId
      this.campaignCodeField = this.campaignCode
      this.campaignNameField = this.campaignName
      this.limitsField = this.limits
      this.openTimeField = this.openTime
      this.closedTimeField = this.closedTime
      this.startTimeField = this.startTime
      this.endTimeField = this.endTime
      this.isDynamic = this.isDynamicCampaign

      // 修正時は最初、「クーポンリストの追加」をしなくても「修正ボタン」を押せるようisSettingDisableをfalseにする
      if (this.isModify) {
        this.isSettingDisabled = false
      }
    },

    turnBack(emitingText: TranslateResult | string) {
      this.coupons = []
      this.fileName = ''
      this.$emit('closeComponent', emitingText)
    },

    async deleteCampaign() {
      const deleteTime = new Date(moment().tz('Asia/Tokyo').format()).getTime()

      this.dialogDelete = false

      // データベースから削除（更新）
      await updateDoc(doc(db, 'campaigns', this.campaignIdField), {
        deletedAt: this.UnixTimeToUTC(deleteTime),
        isDeleted: true,
      })

      this.$emit('deleteItem')
    },

    // 修正時に登録済みのcoupon名を取得
    async getRegisteredCoupons() {
      this.registeredCoupons = []
      const couponsRef = collection(
        db,
        'campaigns',
        this.campaignIdField,
        'coupons'
      )
      const couponsSnapshot = await getDocs(couponsRef)
      couponsSnapshot.forEach((doc) => {
        this.registeredCoupons.push(doc.id)
      })
    },

    // 受験者リストの読み込み用関数
    getFileContent(e: File) {
      if (e === null) {
        this.coupons = []
        this.fileErrorMessage = ''
        if (this.isModify) {
          this.isSettingDisabled = false
        } else {
          this.isSettingDisabled = true
        }
      } else {
        this.fileName = e.name
        const reader = new FileReader()
        const file = e
        const coupons = [] as CouponType[]
        const couponIds = [] as string[]
        let isValidation = true
        let isNull = false
        let isColValidation = true
        let isUploadDuplicated = false

        const isDuplicated = (l1: string[], l2: string[]) => {
          const common = l1.filter((x) => l2.includes(x))
          return common.length !== 0
        }

        const loadFunc = () => {
          var lines = [] as string[]
          if (reader.result !== null) {
            lines = (reader.result as string).split(br)
          }
          let i = 0

          // 最後の列が空白の場合はそれを消す
          if (
            lines[lines.length - 1] === '' ||
            lines[lines.length - 1] === undefined
          ) {
            lines = lines.slice(0, -1) // 最後の要素以外を取り出す
          }

          lines.forEach((element: string) => {
            const couponData = element.split(',')

            // 列が2列以外の場合はvalidationをfalseにする
            if (couponData.length !== 2) {
              isColValidation = false
            } else {
              const couponCode = couponData[0]
              const password = couponData[1]

              if (
                (couponCode === '' && password === undefined) ||
                (couponCode === undefined && password === '') ||
                (couponCode === undefined && password === undefined)
              ) {
                // pass
              } else if (
                (couponCode === '' && password === '') ||
                (couponCode !== '' && password === '') ||
                (couponCode === '' && password !== '')
              ) {
                isNull = true
              } else {
                // 受験者データのアップロード方法が「一時IDとパスワード」の場合
                // クーポンコードのバリデーションチェック
                if (!/^[a-zA-Z0-9_-]*$/.test(couponCode)) {
                  isValidation = false
                }
                // パスワードのバリデーションチェック
                if (!/^[a-zA-Z0-9_-]*$/.test(password)) {
                  isValidation = false
                }

                // アップロードしたクーポン同士で重複がないかチェック
                if (couponIds.includes(couponCode)) {
                  isUploadDuplicated = true
                }

                const coupon = {
                  id: i,
                  couponCode: couponCode,
                  password: password,
                }
                coupons.push(coupon)
                couponIds.push(couponCode)
                i++
              }
            }
          })

          // エラーの表示なようは検討の余地あり
          if (
            this.isModify &&
            isDuplicated(couponIds, this.registeredCoupons)
          ) {
            // 修正時に、新規に登録するクーポンとすでに登録されたクーポンに重複がないか確認
            this.fileErrorMessage = this.$t(
              'campaignSetting.variation.containsRegisteredCoupon'
            ) as string
            this.isSettingDisabled = true
          } else if (isUploadDuplicated) {
            // アップロードしたファイルで、クーポンコード同士に重複がないかチェック
            this.fileErrorMessage = this.$t(
              'campaignSetting.variation.containsDuplicateCoupon'
            ) as string
            this.isSettingDisabled = true
          } else if (!isColValidation) {
            // 列が2列ではなかった場合
            this.fileErrorMessage = this.$t(
              'campaignSetting.variation.invalidFileFormat'
            ) as string
            this.isSettingDisabled = true
          } else if (isNull) {
            // クーポンコード及びパスワードに空白があった場合
            this.fileErrorMessage = this.$t(
              'campaignSetting.variation.blankCouponOrPasswordError'
            ) as string
            this.isSettingDisabled = true
          } else if (!isValidation) {
            // クーポンコード及びパスワードに半角英数字・ハイフン・アンダーバー以外も含まれていた場合
            this.fileErrorMessage = this.$t(
              'campaignSetting.variation.validCouponPasswordFormat'
            ) as string
            this.isSettingDisabled = true
          } else {
            this.coupons = coupons
            this.isSettingDisabled = false
            this.fileErrorMessage = ''
          }
        }

        reader.onload = loadFunc
        reader.readAsBinaryString(file)
      }
    },

    getFileSample() {
      var sampleData = ''
      for (let i = 0; i < 5; i++) {
        var sampleLine = 'couponCode' + i + ',' + 'password' + i + '\n'
        sampleData += sampleLine
      }
      const blob = new Blob([sampleData], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'sample.csv'
      link.click()
    },

    // 確認画面の「登録する」ボタンで呼び出される
    // DBへデータを投げる処理
    async setCampaign() {
      this.dialogConfirm = false
      this.isLoading = true

      const customerId = store.state.customerId

      var campaignId = ''
      if (!this.isModify) {
        // limits, isUnlimitedの処理
        // 新しくテストを設定する際の処理
        campaignId = v4()
        await setDoc(doc(db, 'campaigns', campaignId), {
          customerCode: this.customerCode,
          customerId: customerId,
          campaignCode: this.campaignCodeField,
          name: this.campaignNameField,
          closedAt: this.UnixTimeToUTC(this.closedTimeField),
          endAt: this.UnixTimeToUTC(this.endTimeField),
          openAt: this.UnixTimeToUTC(this.openTimeField),
          startAt: this.UnixTimeToUTC(this.startTimeField),
          scenarioDocId: this.isDynamic
            ? null
            : this.formInput.scenarioSelectItem?.docId,
          category: 'withdrawal', //よくわからん
          deletedAt: null,
          isDeleted: false,
          isDynamic: this.isDynamic,
          isUnlimited: this.isUnlimitedField,
          limits: Number(this.limitsField),
          couponCount: 0,
          systemInfo: {
            Chips: {
              systemVersion: '7.8.2', //よくわからん
            },
          },
        })

        // couponsをデータベースに追加
        // ここからバッチ処理(#111)
        const couponNum = this.coupons.length
        let unaddedCouponCount = couponNum
        const maxBatchSize = 400
        for (let couponAddCounter = 0; couponAddCounter < couponNum; ) {
          let batch = writeBatch(db)
          let batchSize
          if (unaddedCouponCount > maxBatchSize) {
            batchSize = maxBatchSize
          } else {
            batchSize = unaddedCouponCount
          }
          for (let i = 0; i < batchSize; i++) {
            const coupon = this.coupons[couponAddCounter]
            //// 以下は、couponIdをcouponCodeで登録する場合の処理
            let couponId = coupon.couponCode
            if (couponId !== null) {
              couponAddCounter++
              batch.set(doc(db, 'campaigns', campaignId, 'coupons', couponId), {
                couponCode: coupon.couponCode,
                password: coupon.password,
                isConfirmed: false,
              })
            }
            this.couponProgressValue = Math.floor(
              (couponAddCounter / couponNum) * 100
            ) // プログレスバーの処理
          }
          batch.update(doc(db, 'campaigns', campaignId), {
            couponCount: couponAddCounter,
          })
          await batch.commit()
          unaddedCouponCount = unaddedCouponCount - batchSize
        }
      } else if (this.isModify) {
        // ここからバッチ処理(#111)
        const prevCouponCount = this.couponCount
        if (this.coupons.length !== 0) {
          const couponNum = this.coupons.length
          let unaddedCouponCount = couponNum
          const maxBatchSize = 400
          for (let couponAddCounter = 0; couponAddCounter < couponNum; ) {
            let batch = writeBatch(db)
            let batchSize
            if (unaddedCouponCount > maxBatchSize) {
              batchSize = maxBatchSize
            } else {
              batchSize = unaddedCouponCount
            }
            for (let i = 0; i < batchSize; i++) {
              this.couponProgressValue = Math.floor(
                (couponAddCounter / couponNum) * 100
              ) // プログレスバーの処理
              const coupon = this.coupons[couponAddCounter]
              let couponId = coupon.couponCode
              if (couponId !== null) {
                couponAddCounter++
                batch.set(
                  doc(
                    db,
                    'campaigns',
                    this.campaignIdField,
                    'coupons',
                    couponId
                  ),
                  {
                    couponCode: coupon.couponCode,
                    password: coupon.password,
                    isConfirmed: false,
                  }
                )
              }
            }
            batch.update(doc(db, 'campaigns', this.campaignIdField), {
              couponCount: prevCouponCount + couponAddCounter,
            })
            await batch.commit()
            unaddedCouponCount = unaddedCouponCount - batchSize
          }
        }

        await updateDoc(doc(db, 'campaigns', this.campaignIdField), {
          campaignCode: this.campaignCodeField,
          name: this.campaignNameField,
          openAt: this.UnixTimeToUTC(this.openTimeField),
          closedAt: this.UnixTimeToUTC(this.closedTimeField),
          startAt: this.UnixTimeToUTC(this.startTimeField),
          endAt: this.UnixTimeToUTC(this.endTimeField),
          scenarioDocId: this.isDynamic
            ? null
            : this.formInput.scenarioSelectItem?.docId,
          deletedAt: null,
          isDynamic: this.isDynamic,
          isUnlimited: this.isUnlimitedField,
          isShow: false,
          limits: Number(this.limitsField),
        })
      } else {
        // console.log('エラーを表示（そのようなID識別はありません）')
      }

      if (this.isModify) {
        // HomeView.vueでの修正時
        this.turnBack('修正しました')
      } else {
        // NewExamView.vueでの追加時
        this.$router.push({
          name: 'Home',
          params: {
            snackbarAction: 'true',
            snackbarText: 'キャンペーンを追加しました',
            snackbarColor: 'pink',
          },
        })
      }
      this.couponProgressValue = 0
      this.isLoading = false
    },

    setCustomerId() {
      this.customerId = store.state.customerId
    },

    UnixTimeToString(UnixTime: number) {
      return moment(UnixTime).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
    },

    // 時差問題が生じていた原因となる箇所
    UnixTimeToUTC(UnixTime: number) {
      return moment(UnixTime).utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    },

    async getScenarioMap(): Promise<ScenarioMap> {
      const scenariosRef = collection(db, 'scenarios')
      const querySnapshot = await getDocs(
        query(scenariosRef, where('deletedAt', '==', null))
      )
      return Object.fromEntries(
        querySnapshot.docs.map((doc) => [doc.id, doc.data() as Scenario])
      )
    },
    async getAgentGroupMap(): Promise<AgentGroupMap> {
      const agentGroupsRef = collection(db, 'agentGroups')
      const querySnapshot = await getDocs(
        query(agentGroupsRef, where('isDeleted', '==', false))
      )
      return Object.fromEntries(
        querySnapshot.docs.map((doc) => [doc.id, doc.data() as AgentGroup])
      )
    },
    /**
     * キャンペーンコードが重複しているかどうかのチェックを行う。
     */
    async checkCampaignCode() {
      try {
        const campaignRef = collection(db, 'campaigns')
        const queryRef = query(
          campaignRef,
          where('campaignCode', '==', this.campaignCodeField),
          where('isDeleted', '==', false)
        )
        const querySnapshot = await getDocs(queryRef)
        this.isCampaignCodeError = querySnapshot.size > 0
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * collection customersから今ログインしているcustomerのscenarioDocIdsを取得
     */
    async getAllowedScenarioDocIdsForCustomer(): Promise<string[]> {
      const customerId = store.getters.customerId
      const customer = (
        await getDoc(doc(db, 'customers', customerId))
      ).data() as Customer
      return customer.scenarioDocIds
    },

    makeScenarioSelectItem(docId: string): ScenarioSelectItem | null {
      if (
        Object.keys(this.scenarioMap).length === 0 ||
        Object.keys(this.agentGroupMap).length === 0
      )
        return null
      const scenario = this.scenarioMap[docId]
      if (scenario === undefined) return null
      const agentGroup = this.agentGroupMap[scenario.agentGroupId]
      return {
        docId,
        label: `${scenario.name} - ${agentGroup.name}`,
        ...scenario,
      }
    },
    fetchLimitList() {
      return [
        this.$t('campaignSetting.interviewCountOption.specifyNumber') as string,
        this.$t('campaignSetting.interviewCountOption.unlimited') as string,
      ]
    },
    async resetIsdynamic() {
      try {
        if (!this.campaignId) {
          this.isDynamic = false
          return
        }
        const docSnap = await getDoc(doc(db, 'campaigns', this.campaignId))
        if (docSnap.exists()) {
          const data = docSnap.data()
          this.isDynamic = !!data.isDynamic
        } else {
          this.isDynamic = false
        }
      } catch (error) {
        console.error(error)
        this.isDynamic = false
      }
    },
  },
})
