import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,{staticClass:"card-text text-caption"},[_c('span',{staticStyle:{"background-color":"white"}},[_vm._v(_vm._s(_vm.cardTitle))])]),_c(VContainer,[_c(VRow,{staticClass:"mt-n10 mb-n7",attrs:{"justify":"center"}},[_c(VCol,{staticClass:"mb-n10",attrs:{"cols":"10","lg":"6"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Date","persistent-hint":"","prepend-icon":"mdi-calendar","rules":[_vm.rules.required, _vm.rules.dateFormat],"maxlength":"10","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c(VCol,{attrs:{"cols":"7","lg":"5"}},[_c(VCombobox,{attrs:{"persistent-hint":"","label":"time","items":_vm.times,"rules":[_vm.rules.required, _vm.rules.required2, _vm.rules.timeFormat],"maxlength":"5"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }