
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CardHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#1b2643',
    },
  },
})
