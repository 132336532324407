import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VForm,[(_vm.isSecurity)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"append-icon":_vm.showPassword1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[
          _vm.rules.required,
          _vm.rules.min,
          _vm.rules.characterType,
          _vm.rules.characterSelect,
        ],"type":_vm.showPassword1 ? 'text' : 'password',"label":_vm.$t('common.password'),"persistent-placeholder":"","outlined":""},on:{"click:append":function($event){_vm.showPassword1 = !_vm.showPassword1}},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}})],1)],1):_vm._e(),(_vm.isSecurity)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"append-icon":_vm.showPassword2 ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required],"type":_vm.showPassword2 ? 'text' : 'password',"label":_vm.$t('common.passwordConfirm'),"persistent-placeholder":"","outlined":""},on:{"click:append":function($event){_vm.showPassword2 = !_vm.showPassword2}},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}})],1)],1):_vm._e(),(_vm.isSignUp)?_c(VRow,{staticClass:"mt-n7",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VTextField,{staticClass:"filled_noline",attrs:{"append-icon":_vm.showPassword1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[
          _vm.rules.required,
          _vm.rules.min,
          _vm.rules.characterType,
          _vm.rules.characterSelect,
        ],"type":_vm.showPassword1 ? 'text' : 'password',"label":_vm.$t('common.password'),"filled":"","dense":""},on:{"click:append":function($event){_vm.showPassword1 = !_vm.showPassword1}},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}})],1)],1):_vm._e(),(_vm.isSignUp)?_c(VRow,{staticClass:"mt-n7",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VTextField,{staticClass:"filled_noline",attrs:{"append-icon":_vm.showPassword2 ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required],"type":_vm.showPassword2 ? 'text' : 'password',"label":_vm.$t('common.passwordConfirm'),"filled":"","dense":""},on:{"click:append":function($event){_vm.showPassword2 = !_vm.showPassword2}},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}})],1)],1):_vm._e(),_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.samePass),expression:"samePass"}],attrs:{"justify":"center"}},[_c('p',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('passwordVariation.passwordMismatch')))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }