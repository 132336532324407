import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"ma-10 card_shadow",attrs:{"width":"60vw"}},[_c(VForm,{ref:"form"},[_c(VContainer,{staticClass:"pt-5"},[_c(VRow,{staticClass:"headline mt-n5"},[_c(VCol,{staticClass:"active_text"},[_c('p',[_vm._v(_vm._s(_vm.$t('newLearners.simpleSetupDescription')))])])],1),_c(VRow,{staticClass:"ml-10 mt-10 mr-10"},[_c('p',{staticClass:"text-body-2",domProps:{"innerHTML":_vm._s(_vm.$t('newLearners.userTestSetupGuide'))}})]),_c(VRow,{staticClass:"mt-5"},[_c(VCol,{attrs:{"cols":"7"}},[_c(VFileInput,{attrs:{"accept":"text/csv","label":_vm.$t('newLearners.newAccountFile'),"value":"a","persistent-placeholder":"","outlined":""},on:{"change":_vm.getFileContent}})],1),_c(VCol,{attrs:{"cols":"5"}},[_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.$t('newLearners.formatCSV')))])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }