
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SnackBar',
  props: {
    snackbarAction: { type: Boolean, default: false },
    snackbarText: { type: String, default: '' },
    snackbarColor: { type: String, default: '' },
    snackbarTimeout: { type: Number, default: 6000 }, // timeoutのデフォルト値：6000ms
  },
  data() {
    return {
      snackbarActionBtn: false,
      vertical: true,
      activeTimeout: -1,
    }
  },
  watch: {
    snackbarAction(newValue) {
      this.snackbarActionBtn = newValue
      this.setTimeout()
    },
  },
  methods: {
    // snackBar表示のタイムアウトはv-snackbar内のtimeout指定ではなく以下のsetTimeout()で実行
    // タイムアウトでsnackbar消滅時にcloseSnackBar()を実行し正常終了させる
    setTimeout() {
      window.clearTimeout(this.activeTimeout)
      this.activeTimeout = window.setTimeout(() => {
        this.closeSnackbar()
      }, this.snackbarTimeout)
    },
    closeSnackbar() {
      this.snackbarActionBtn = false
      this.$emit('closeSnackbar')
    },
  },
})
