
import { defineComponent } from '@vue/composition-api'
import SetExam from '@/components/Dashboard/PageViewItems/SetExamCardComponent.vue'

export default defineComponent({
  name: 'NewExamView',
  components: {
    SetExam,
  },
  setup() {
    return {}
  },
})
