
import { defineComponent } from '@vue/composition-api'
import store from '@/store'
import { i18n } from '@/locales/i18n'
import LanguageSwitching from '@/components/Common/LanguageSwitchingComponent.vue'

export default defineComponent({
  name: 'SideBar',
  components: {
    LanguageSwitching,
  },
  data() {
    return {
      drawer: true,
      mini: false,
    }
  },
  setup() {
    // サイドバーにstore.state.emailを表示、存在しない場合はnot found（アクセス制御で回避しているので本来は発生しない）
    let email = 'err: not found'
    if (store.state.email) {
      email = store.state.email
    }

    type Item = {
      icon: string
      title: string
      path: string
      sub?: {
        icon: string
        title: string
        path: string
      }[]
    }
    const items: Item[] = [
      {
        icon: 'mdi-view-grid',
        title: 'home.navigationBar.home',
        path: '/dashboard/home',
      },
      {
        icon: 'mdi-pen-plus',
        title: 'home.navigationBar.campaignSetting',
        path: '/dashboard/exam/new',
      },
      {
        icon: 'mdi-card-account-details',
        title: 'home.navigationBar.searchTesterData',
        path: '/dashboard/learners',
      },
      {
        icon: 'mdi-ticket-confirmation-outline',
        title: 'home.navigationBar.reviewCouponList',
        path: '/dashboard/coupons',
      },
      {
        icon: 'mdi-shield-lock-outline',
        title: 'home.navigationBar.securitySetting',
        path: '/dashboard/security',
      },
      {
        icon: 'mdi-logout',
        title: 'home.navigationBar.signOut',
        path: '/dashboard/signout',
      },
      // 操作ガイドを下にする場合は以下のまとまりをコメントアウトしてください
      {
        icon: 'mdi-information-outline',
        title: 'home.navigationBar.operatingGuide',
        path: '/dashboard/guide',
      },
    ]
    const guide = {
      icon: 'mdi-information-outline',
      title: '操作ガイド',
      path: '/dashboard/guide',
    }

    const languageSwitchingItems = [
      { title: '日本語', value: 'ja' },
      { title: 'English', value: 'en' },
    ]

    const languageSwitching = (value: string) => {
      i18n.locale = value
    }

    return { items, guide, email, languageSwitchingItems, languageSwitching }
  },
})
