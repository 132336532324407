
import { defineComponent } from '@vue/composition-api'
import { auth } from '@/plugins/firebase'
import { signOut } from 'firebase/auth'

export default defineComponent({
  name: 'SignOutView',
  data() {
    return {
      //   snackbarAction: false,
    }
  },
  methods: {
    onClickSignOut() {
      // サインアウト
      signOut(auth)
        .then(() => {
          this.$store.commit('reset', this.$store.state)
          //console.log('サインアウトに成功しました')
          this.$router.push({
            name: 'Sign In',
          })
        })
        .catch((error) => {
          // console.log(`サインアウト時にエラーが発生しました: (${error})`)
          console.log('error code:', error.code)
        })
    },
  },
})
