
import { defineComponent } from '@vue/composition-api'
import { i18n } from '@/locales/i18n'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
  data: () => ({
    languageListItems: [
      { title: '日本語', value: 'ja' },
      { title: 'English', value: 'en' },
    ],
  }),
  methods: {
    switchLanguage(value: string) {
      window.localStorage.setItem('language', value)
      i18n.locale = value
    },
  },
})
