
import { defineComponent } from '@vue/composition-api'
import SideBar from '@/components/Dashboard/SideBarComponent.vue'
import BottomBar from '@/components/Common/BottomBarComponent.vue'

export default defineComponent({
  name: 'DashboardLayout',
  components: {
    SideBar,
    BottomBar,
  },
  setup() {
    return {}
  },
})
