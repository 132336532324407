import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"ma-10 card_shadow",attrs:{"width":"60vw"}},[_c(VForm,{ref:"form"},[_c(VContainer,{staticClass:"pt-5"},[_c(VRow,{staticClass:"headline mt-n5"},[_c(VCol,{staticClass:"active_text"},[_c('p',[_vm._v(_vm._s(_vm.$t('signOut.signOutTitle')))])])],1),_c(VRow,{staticClass:"mt-8",attrs:{"justify":"center"}},[_c('p',[_vm._v(_vm._s(_vm.$t('signOut.signOutConfirmMessage')))])]),_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"mb-7 white--text btn_shadow",attrs:{"color":"#1b2643"},on:{"click":_vm.onClickSignOut}},[_vm._v(" "+_vm._s(_vm.$t('signOut.signOutTitle'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }