
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LoadingCircle',
  props: {
    isLoading: { type: Boolean, default: false },
    text: { type: String, default: '' },
    progressValue: { type: Number, default: 0 },
  },
  data() {
    return {
      isLoadingField: false,
      textField: '',
      progressValueField: 0,
    }
  },
  mounted() {
    this.isLoadingField = this.isLoading
    this.textField = this.text
    this.progressValueField = this.progressValue
  },
  watch: {
    isLoading(newValue) {
      this.isLoadingField = newValue
    },
    text(newValue) {
      this.textField = newValue
    },
    progressValue(newValue) {
      this.progressValueField = newValue
    },
  },
  methods: {},
})
