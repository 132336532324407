
import { defineComponent } from '@vue/composition-api'
import moment from 'moment-timezone'

const interval = 15
const datesContent = [] as string[]
for (let h = 0; h < 24; h++) {
  for (let m = 0; m < 60; m += interval) {
    let time = String(('0' + h).slice(-2)) + ':' + String(('0' + m).slice(-2))
    datesContent.push(time)
  }
}

export default defineComponent({
  name: 'SetSchedule',

  props: {
    cardTitle: { type: String, default: '' },
    unixDateTime: { type: Number, default: 0 },
  },

  data() {
    return {
      date: moment().tz('Asia/Tokyo').format('YYYY-MM-DD'),
      menu: false,
      times: datesContent,
      time: 'hh:mm',
      dateTime: new Date(moment().tz('Asia/Tokyo').format()),
      unixDateTimeField: 0,

      rules: {
        required: (value: string) => !!value || '未入力です',
        required2: (value: string) => value !== 'hh:mm' || '未入力です',
        dateFormat: (value: string) =>
          /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value) ||
          'yyyy-mm-ddの形で入力してください',
        timeFormat: (value: string) =>
          /([01][0-9]|2[0-3]):[0-5][0-9]/.test(value) ||
          'hh:mmの形で入力してください',
      },
    }
  },

  created() {
    this.initialize()
  },

  mounted() {
    if (this.unixDateTimeField != 0) {
      this.UnixTimeTrancerate(this.unixDateTimeField)
    }
  },

  watch: {
    date() {
      if (
        /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(this.date) &&
        /([01][0-9]|2[0-3]):[0-5][0-9]/.test(this.time)
      ) {
        this.formatDateTime()
        this.$emit('changeDateTime', this.dateTime.getTime())
      }
    },
    time() {
      if (
        /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(this.date) &&
        /([01][0-9]|2[0-3]):[0-5][0-9]/.test(this.time)
      ) {
        this.formatDateTime()
        this.$emit('changeDateTime', this.dateTime.getTime())
      }
    },
    unixDateTime(newValue) {
      this.unixDateTimeField = newValue
      this.UnixTimeTrancerate(newValue)
      this.formatDateTime()
    },
  },

  methods: {
    initialize() {
      this.unixDateTimeField = this.unixDateTime
    },

    formatDateTime() {
      this.dateTime = new Date(this.date + ':' + this.time + '+09:00')
    },

    UnixTimeTrancerate(UnixTime: number) {
      this.date = moment(UnixTime).tz('Asia/Tokyo').format('YYYY-MM-DD')
      this.time = moment(UnixTime).tz('Asia/Tokyo').format('HH:mm')
    },
  },
})
