
import { defineComponent } from '@vue/composition-api'
import { db } from '@/plugins/firebase'
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  deleteDoc,
  setDoc,
  getDoc,
  limit,
  startAfter,
  orderBy,
} from 'firebase/firestore'
import store from '@/store'
import moment from 'moment-timezone'
import { functions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'

type CouponType = {
  couponCode: string
  password: string
  isConfirmed: boolean
}

type CampaignType = {
  customerCode: string
  customerId: string
  campaignId: string
  campaignCode: string
  campaignName: string
  couponCount: number
  isShow: boolean
  isDeleted: boolean
  totalTicketCount: number | null
}

type StatusSummaryType = {
  availableTicketCount: number
  totalTicketCount: number
  usedTicketCount: number
}

type TicketStatusSummaryType = {
  ticketStatusSummary: StatusSummaryType[]
}

type CampaignCodeToStatusSummaryType = {
  [campaignCode: string]: StatusSummaryType
}

export default defineComponent({
  name: 'CouponsView',
  components: {},

  data() {
    return {
      campaignData: [] as CampaignType[],
      coupons: [] as CouponType[][],
      isShowCoupons: false,

      campaign: {} as CampaignType,
      coupon: {} as CouponType,

      couponField: {} as CouponType,
      couponCodeField: '',
      passwordField: '',
      isConfirmedField: false,

      dialogEdit: false,
      dialogEditConfirm: false,
      dialogDelete: false,
      dialogError: false,

      isConfirmedPresent: true,
      isUnique: true,

      rules: {
        required: (value: string) => !!value || '未入力です',
        halfSize: (value: string) =>
          /^[a-zA-Z0-9_-]*$/.test(value) ||
          '半角英数字・ハイフン・アンダーバーで入力してください',
      },

      isFirstReader: true,
      lastCouponCode: '',
      currentPage: 1,
      totalPageNum: 0,
      couponFilter: '全て',
      couponFilterBool: true,
      campaignCodes: [] as string[],
      isLoadingCampaigns: false,
      isLoadingCoupons: false,
      isFetchingCoupons: false,
      couponLoadProgressValue: 0,
    }
  },

  async mounted() {
    await this.readData()
  },

  computed: {
    validation() {
      let isDisabled = false
      // クーポンコードが未入力だった場合
      if (this.couponCodeField.length === 0) {
        isDisabled = true
      }
      // パスワードが未入力だった場合
      if (this.passwordField.length === 0) {
        isDisabled = true
      }
      // // クーポンコードに半角英数字・ハイフン・アンダーバー以外の文字が含まれていた場合
      if (!/^[a-zA-Z0-9_-]*$/.test(this.couponCodeField)) {
        isDisabled = true
      }
      // // パスワードに半角英数字・ハイフン・アンダーバー以外の文字が含まれていた場合
      if (!/^[a-zA-Z0-9_-]*$/.test(this.passwordField)) {
        isDisabled = true
      }
      return isDisabled
    },
  },

  methods: {
    async readData() {
      this.isLoadingCampaigns = true
      this.campaignData = []
      const campaignRef = collection(db, 'campaigns')
      const customerId = store.getters.customerId

      const campaignsSnapshot = await getDocs(
        query(
          campaignRef,
          where('customerId', '==', customerId),
          where('isDeleted', '==', false)
        )
      )

      campaignsSnapshot.forEach((doc) => {
        const data = doc.data()
        this.campaignCodes.push(data.campaignCode)
        this.campaignData.push({
          customerCode: data.customerCode,
          customerId: data.customerId,
          campaignId: doc.id,
          campaignCode: data.campaignCode,
          campaignName: data.name,
          couponCount: data.couponCount,
          isShow: true,
          isDeleted: false,
          totalTicketCount: null,
        })
      })

      this.isLoadingCampaigns = false

      // チケット登録済みクーポン数の取得
      await this.updateTicketStatusSummary()
    },

    async updateTicketStatusSummary() {
      this.isLoadingCoupons = true
      const getTicketStatusSummary = httpsCallable(
        functions,
        'getTicketStatusSummary'
      )

      const inputData = { campaignCodes: this.campaignCodes }
      try {
        const result = await getTicketStatusSummary(inputData)
        const data = result.data as TicketStatusSummaryType
        const campaignCodeToStatusSummaryType: CampaignCodeToStatusSummaryType =
          {}

        for (let campaignCode in data.ticketStatusSummary) {
          campaignCodeToStatusSummaryType[campaignCode] =
            data.ticketStatusSummary[campaignCode]
        }
        this.campaignData.forEach((campaign) => {
          campaign.totalTicketCount =
            campaignCodeToStatusSummaryType[
              campaign.campaignCode
            ].totalTicketCount
        })
      } catch (error) {
        console.log('err code: ', error)
      } finally {
        this.isLoadingCoupons = false
      }
    },

    // クーポンリスト表示時のフィルタ機能のための関数
    async filterCoupons() {
      if (this.couponFilter === 'チケット登録済みクーポンのみ') {
        this.couponFilterBool = true
      } else if (this.couponFilter === 'チケット未登録クーポンのみ') {
        this.couponFilterBool = false
      }
    },

    // クーポンリスト表示時に使用するクーポンリスト取得のための関数
    async getCoupons(campaignId: string) {
      this.isShowCoupons = true
      this.isFetchingCoupons = true
      this.coupons = [] as CouponType[][]
      this.lastCouponCode = ''
      this.couponLoadProgressValue = 0
      this.totalPageNum = 0

      const fetchCoupons = async (startAfterCouponCode: string) => {
        return await getDocs(
          query(
            collection(db, 'campaigns', campaignId, 'coupons'),
            orderBy('couponCode'),
            startAfter(startAfterCouponCode),
            limit(5000)
          )
        )
      }

      let couponsSnapshot = await fetchCoupons(this.lastCouponCode)
      const couponNum: number = this.campaign.couponCount
      let remainingCouponNum: number = couponNum

      while (remainingCouponNum > 0) {
        let couponsPerPage = [] as CouponType[]
        // 1回のfetchで5000件のクーポンを取得し、2回fetchすることで1万件のクーポンを取得する
        for (let i = 0; i < 2; i++) {
          couponsSnapshot.forEach((coupon) => {
            const couponData = coupon.data()
            couponsPerPage.push({
              couponCode: couponData.couponCode,
              password: couponData.password,
              isConfirmed: couponData.isConfirmed,
            })
          })
          this.lastCouponCode =
            couponsPerPage[couponsPerPage.length - 1].couponCode
          couponsSnapshot = await fetchCoupons(this.lastCouponCode)

          remainingCouponNum = Math.max(remainingCouponNum - 5000, 0)
        }

        this.couponLoadProgressValue = Math.floor(
          ((couponNum - remainingCouponNum) / couponNum) * 100
        )
        this.coupons.push(couponsPerPage)
      }
      this.isFetchingCoupons = false
      this.totalPageNum = Math.ceil(couponNum / 10000)
    },

    // クーポン編集時にチケット登録がされていないか確認するための関数
    async checkConfirmation(campaignId: string, couponCode: string) {
      return await getDoc(
        doc(db, 'campaigns', campaignId, 'coupons', couponCode)
      ).then((coupon) => {
        const isConfirmedPresent = coupon.data()?.isConfirmed
        this.isConfirmedPresent = isConfirmedPresent
        return isConfirmedPresent
      })
    },

    // クーポン編集時にクーポンコードが重複していないか確認するための関数
    async checkUniqueness(
      couponsList: CouponType[][],
      oldCouponCode: string,
      newCouponCode: string
    ) {
      let isUnique = true
      couponsList.forEach((coupons) => {
        coupons.forEach((coupon) => {
          if (
            coupon.couponCode === newCouponCode &&
            oldCouponCode !== newCouponCode
          ) {
            isUnique = false
          }
        })
      })
      this.isUnique = isUnique
      return isUnique
    },

    // クーポン編集のための関数
    async editCoupon(
      campaignId: string,
      coupon: CouponType,
      couponCodeField: string,
      passwordField: string,
      couponsList: CouponType[][]
    ) {
      const isUnique = await this.checkUniqueness(
        couponsList,
        coupon.couponCode,
        couponCodeField
      )
      const isConfirmedPresent = await this.checkConfirmation(
        campaignId,
        coupon.couponCode
      )
      if (!isConfirmedPresent && isUnique) {
        await deleteDoc(
          doc(db, 'campaigns', campaignId, 'coupons', coupon.couponCode)
        )
        await setDoc(
          doc(db, 'campaigns', campaignId, 'coupons', couponCodeField),
          {
            couponCode: couponCodeField,
            password: passwordField,
            isConfirmed: isConfirmedPresent,
          }
        )
        await this.getCoupons(campaignId)
      } else {
        this.dialogError = true
      }
    },

    // クーポン削除のための関数
    async deleteCoupon(campaignId: string, couponCode: string) {
      const isConfirmedPresent = await this.checkConfirmation(
        campaignId,
        couponCode
      )
      if (!isConfirmedPresent) {
        await deleteDoc(doc(db, 'campaigns', campaignId, 'coupons', couponCode))
        const campaignRef = await getDoc(doc(db, 'campaigns', campaignId))
        if (campaignRef.exists()) {
          let couponCount = campaignRef.data().couponCount - 1
          this.campaign.couponCount = couponCount
          await updateDoc(doc(db, 'campaigns', campaignId), {
            couponCount: couponCount,
          })
        }
        await this.getCoupons(campaignId)
      } else {
        this.dialogError = true
      }
    },

    // クーポンリストのcsvファイルダウンロード機能に関わる関数
    async downloadCsv(couponList: CouponType[][]) {
      var couponCsv = 'CouponCode, Password, Ticket Registration'
      couponList.forEach((coupons) => {
        coupons.forEach((coupon) => {
          let couponCode = coupon.couponCode
          let password = coupon.password
          var ticket
          if (coupon.isConfirmed) {
            ticket = 'completed'
          } else {
            ticket = 'incomplete'
          }
          let couponLine = '\n' + couponCode + ', ' + password + ', ' + ticket
          couponCsv += couponLine
        })
      })
      const blob = new Blob([couponCsv], {
        type: 'text/csv',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      let now = new Date(moment().tz('Asia/Tokyo').format())
      link.download = 'couponList_' + now.getTime() + '.csv'
      link.click()
      link.remove()
    },
  },
})
