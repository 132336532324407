/**
 * Determines the CSS class for a given CEFR level.
 * @param {string} cefrLevel - The CEFR level to determine the class for.
 * @returns {string} - The CSS class associated with the given CEFR level.
 */
export function cefrStyleClass(cefrLevel: string): string {
  if (cefrLevel === 'Unrated' || cefrLevel === '判定対象外') {
    return 'grey lighten-3 grey--text text--darken-3 text-center'
  }

  const cefrLevelMatch = cefrLevel.match(/(Pre-A1|A1|A2|B1|B2|C1|C2)/)
  if (!cefrLevelMatch) return ''
  const level = cefrLevelMatch[0]

  switch (level) {
    case 'Pre-A1':
    case 'A1':
      return 'light-green lighten-5 light-green--text text--darken-1 text-center'
    case 'A2':
      return 'light-green lighten-3 light-green--text text--darken-3 text-center'
    case 'B1':
      return 'blue lighten-5 blue--text text--darken-1 text-center'
    case 'B2':
      return 'blue lighten-3 blue--text text--darken-3 text-center'
    case 'C1':
      return 'pink lighten-5 pink--text text--darken-1 text-center'
    case 'C2':
      return 'pink lighten-3 pink--text text--darken-3 text-center'
    default:
      return ''
  }
}

/**
 * Rounds a number up to two decimal places.
 * This method multiplies the input value by 100, rounds it up to the nearest integer,
 *
 * @param {number} value - The number to be rounded.
 * @returns {number} The number rounded up to two decimal places.
 */
export function roundToTwoDecimals(value: number) {
  return (Math.ceil(value * 100) / 100).toFixed(2)
}
