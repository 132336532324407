import { render, staticRenderFns } from "./GuideView.vue?vue&type=template&id=212c2700&scoped=true&"
import script from "./GuideView.vue?vue&type=script&lang=ts&"
export * from "./GuideView.vue?vue&type=script&lang=ts&"
import style0 from "./GuideView.vue?vue&type=style&index=0&id=212c2700&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212c2700",
  null
  
)

export default component.exports