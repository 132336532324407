
import { defineComponent } from '@vue/composition-api'

type NewStudentType = {
  tempId: string
  studentId: string
  tempPassword: string
}

export default defineComponent({
  name: 'NewLearnersView',
  data() {
    return {
      fileName: '',
      students: [] as NewStudentType[],
    }
  },
  methods: {
    getFileContent(e: File) {
      this.fileName = e.name
      const reader = new FileReader()
      const file = e
      const students: NewStudentType[] = []

      const loadFunc = () => {
        if (reader.result !== null) {
          const lines = (reader.result as string).split('\n')
          lines.forEach((element: string) => {
            const studentData = element.split(',')

            // ここの条件分岐はcsvの仕様によって変更
            if (studentData.length === 3) {
              const student = {
                tempId: studentData[0],
                studentId: studentData[1],
                tempPassword: studentData[2],
              }
              students.push(student)
            }
          })
        }
        this.students = students
      }

      reader.onload = loadFunc
      reader.readAsBinaryString(file)
    },
  },
})
