
import { defineComponent } from '@vue/composition-api'
import { db, auth } from '@/plugins/firebase'
import {
  doc,
  collection,
  setDoc,
  query,
  getDocs,
  where,
} from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from 'firebase/auth'

import InputNewPass from '@/components/Dashboard/PageViewItems/InputNewPasswordField.vue'
import LanguageSwitching from '@/components/Common/LanguageSwitchingComponent.vue'

export default defineComponent({
  name: 'SignUpView',
  components: {
    InputNewPass,
    LanguageSwitching,
  },
  data() {
    return {
      candidateRef: null,
      candidate: {
        customerCode: '',
        customerName: '',
        email: '',
        password: '',
        isAccepted: false,
      },
      snackbar: false,
      snackBarMessage: 'データベース接続エラー',

      show1: true,
      show2: true,

      password: '',

      showPassword3: false,
      customerName: '',
      customerId: '',
      uid: '',
      isPasswordValid: true,
      isEmailValid: false,
      isCustomerCodeValid: false,
      signUpError: null as string | null,
    }
  },

  created() {
    // サインイン画面を開くと強制サインアウトおよびストレージのリセット
    signOut(auth).then(() => {
      this.$store.commit('reset', this.$store.state)
    })
  },

  methods: {
    async signUp() {
      this.show2 = !this.show2
      // Auth認証及びエラーハンドリング
      try {
        await createUserWithEmailAndPassword(
          auth,
          this.candidate.email,
          this.password
        )
      } catch (error) {
        if (error && typeof error === 'object' && 'code' in error) {
          this.signUpError = (error as { code: string }).code
        }
        console.log(error)
      }

      if (this.signUpError) {
        this.show1 = !this.show1
        this.show2 = !this.show2
        return
      }

      if (auth.currentUser) {
        this.uid = auth.currentUser.uid
        // store.state.emailがnullの状態でauthのstateが変更されると、
        // router設定の不正画面遷移処理の影響でサインインにリダイレクトすることを回避
        this.$store.commit('setEmail', auth.currentUser.email)
        // console.log('次のユーザに認証メールを送付します:', auth.currentUser.uid)
        const url = `${window.location.origin}/verified`
        sendEmailVerification(auth.currentUser, { url })
      }

      // CustomerName, customerIdを取得
      // await this.setCustomerIdAndName(this.candidate.customerCode)
      // console.log('CustomerNameを取得: ', this.customerName)
      // console.log('CustomerIdを取得:', this.customerId)

      // データ追加（ドキュメント自動作成）
      await setDoc(doc(db, 'candidates', this.uid), {
        customerCode: this.candidate.customerCode,
        email: this.candidate.email,
        isAccepted: this.candidate.isAccepted,
      })
    },

    async setCustomerIdAndName(customerCode: string) {
      // Auth登録後にcustomerIdを取得
      // customerCodeがuniqueが確定的な場合、本メソッドはgetDocs, docSnap.forEachを中心に全体的な修正が必要
      const q = query(
        collection(db, 'customers'),
        where('customerCode', '==', customerCode)
      )
      // customerCodeがヒットしなかった時の例外処理追加必要
      const docSnap = await getDocs(q)

      // 初回に一致のときのみcustomerName, Idをセット（ただし通常、ループは1回のみなので実質動作は不変）
      docSnap.forEach((doc) => {
        if (this.customerName == '') {
          this.customerName = doc.data().name
          this.customerId = doc.id
        }
      })
    },
    goToSignIn() {
      this.$router.push({ name: 'Sign In' })
    },
    // コンポーネントからの値の受け取り
    setPassword(pass: string) {
      this.password = pass
    },
    setPasswordValidity(isPasswordValid: boolean) {
      this.isPasswordValid = isPasswordValid
    },
    /**
     * inputイベントが発生時にエラーフラグを初期化して、isCustomerCodeValid、isEmailValidのチェックを行う。
     */
    checkValidity() {
      this.signUpError = ''
      this.isCustomerCodeValid =
        this.rules.onlyAlphaNumericHyphen(this.candidate.customerCode) !== true
      this.isEmailValid = this.rules.emailRules(this.candidate.email) !== true
    },
  },
  computed: {
    isButtonDisabled() {
      return !(
        !this.isPasswordValid &&
        !this.isCustomerCodeValid &&
        !this.isEmailValid
      )
    },
    rules() {
      return {
        required: (v: string) => !!v || this.$t('signUp.required'),
        emailRules: (v: string) => {
          const hasEmailFormat = /.+@.+\..+/.test(v)
          return hasEmailFormat || this.$t('signUp.invalidEmailFormat')
        },
        onlyAlphaNumericHyphen: (v: string) => {
          const isOnlyAlphaNumericHyphen = /^[a-zA-Z0-9-]+$/.test(v)
          return (
            isOnlyAlphaNumericHyphen || this.$t('signUp.alphaNumericHyphenOnly')
          )
        },
      }
    },
    emailErrorMessages() {
      if (this.signUpError === 'auth/email-already-in-use') {
        return this.$t('signUp.emailAlreadyUsed')
      }
      if (this.signUpError === 'auth/invalid-email') {
        return this.$t('signUp.invalidEmailFormat')
      }
      return ''
    },
  },
})
