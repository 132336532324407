import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.error)?_c(VCard,{staticClass:"ma-10 card_shadow"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"headline"},[_c(VCol,{staticClass:"active_text"},[_c('p',[_vm._v(_vm._s(_vm.$t('campaignResult.cardTitle')))])])],1),_c(VRow,[_c(VCol,[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('campaignResult.campaignNameError')))])])],1)],1)],1):_c(VCard,{staticClass:"ma-10 card_shadow"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"headline"},[_c(VCol,{staticClass:"active_text"},[_c('p',[_vm._v(_vm._s(_vm.$t('campaignResult.cardTitle')))])])],1),_c(VRow,{staticClass:"pl-6"},[_c(VCol,{staticClass:"bold-large-text"},[_vm._v(_vm._s(_vm.campaignCodeName))])],1),_c(VRow,{staticClass:"pl-6"},[_c(VCol,[_vm._v(_vm._s(_vm.$t('campaignResult.lastRetrievedTime'))+": "+_vm._s(_vm.lastRetrievedTime))])],1),_c(VRow,{staticClass:"pl-6"},[_c(VCol,[_c(VDataTable,{ref:"dataTable",staticClass:"freeze-columns",attrs:{"dense":"","multi-sort":"","headers":_vm.headers,"items":_vm.recalculateRows(),"footer-props":{
              'items-per-page-options': [100, 300, 1000, 5000, -1],
            },"loading":_vm.loading,"loading-text":_vm.$t('campaignResult.loadingText')},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.couponCode))]),_c('td',{staticClass:"displayName-column"},[_vm._v(_vm._s(item.displayName))]),(item.status === _vm.$t('campaignResult.status.analyzed'))?_c('td',{staticClass:"dialogId-column"},[_c('router-link',{attrs:{"to":`/dashboard/conversation-analysis/${item.dialogId}`}},[_vm._v(" "+_vm._s(item.dialogId)+" ")])],1):_c('td',{staticClass:"dialogId-column"},[_vm._v(_vm._s(item.dialogId))]),_c('td',[_vm._v(_vm._s(item.status))]),_c('td',[_vm._v(_vm._s(item.startedAt))]),_c('td',[_vm._v(_vm._s(item.completedAt))]),_c('td',[_vm._v(_vm._s(item.duration))]),_c('td',{class:_vm.cefrStyleClass(item.overall)},[_vm._v(" "+_vm._s(item.overall)+" ")]),_c('td',{class:_vm.cefrStyleClass(item.range)},[_vm._v(" "+_vm._s(item.range)+" ")]),_c('td',{class:_vm.cefrStyleClass(item.accuracy)},[_vm._v(" "+_vm._s(item.accuracy)+" ")]),_c('td',{class:_vm.cefrStyleClass(item.phonology)},[_vm._v(" "+_vm._s(item.phonology)+" ")]),_c('td',{class:_vm.cefrStyleClass(item.fluency)},[_vm._v(" "+_vm._s(item.fluency)+" ")]),_c('td',{class:_vm.cefrStyleClass(item.coherence)},[_vm._v(" "+_vm._s(item.coherence)+" ")]),_c('td',{class:_vm.cefrStyleClass(item.interaction)},[_vm._v(" "+_vm._s(item.interaction)+" ")])])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }