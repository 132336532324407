
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'InputNewPass',
  props: {
    isSecurity: { type: Boolean, default: false },
    isSignUp: { type: Boolean, default: false },
  },
  data() {
    return {
      password1: '',
      password2: '',
      showPassword1: false,
      showPassword2: false,
      rules: {
        required: (value: string) =>
          !!value || this.$t('passwordVariation.required'),
        min: (value: string) =>
          value.length >= 8 ||
          this.$t('passwordVariation.minimumEightCharactersRequired'),
        characterType: (value: string) =>
          /^[a-zA-Z\d]+$/.test(value) ||
          this.$t('passwordVariation.alphanumericOnlyAllowed'),
        characterSelect: (value: string) =>
          (/[a-z]+/.test(value) && /[A-Z]+/.test(value) && /\d+/.test(value)) ||
          this.$t('passwordVariation.useAllCasesAndNumber'),
      },
    }
  },
  computed: {
    inputed() {
      if (!/^[a-zA-Z\d]+$/.test(this.password1)) {
        return true
      } else if (!/[a-z]+/.test(this.password1)) {
        return true
      } else if (!/[A-Z]+/.test(this.password1)) {
        return true
      } else if (!/\d+/.test(this.password1)) {
        return true
      } else if (this.password1.length < 8) {
        return true
      } else if (this.password1 != this.password2) {
        return true
      } else {
        return false
      }
    },
    samePass() {
      if (this.password1 != this.password2) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    password1() {
      this.$emit('setPassword', this.password1)
      this.$emit('validatePassword', this.inputed)
    },
    password2() {
      this.$emit('setPassword', this.password1)
      this.$emit('validatePassword', this.inputed)
    },
  },
})
