
import { defineComponent } from '@vue/composition-api'
import { db } from '@/plugins/firebase'
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  doc,
} from 'firebase/firestore'
import { functions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import store from '@/store'
// import LoadingCircle from '@/components/Dashboard/PageViewItems/LoadingCircleComponent.vue'
import SetExam from '@/components/Dashboard/PageViewItems/SetExamCardComponent.vue'
import SnackBar from '@/components/Dashboard/PageViewItems/SnackbarComponent.vue'
import SetSchedule from '@/components/Dashboard/PageViewItems/SetScheduleComponent.vue'
import moment from 'moment-timezone'
import type { Campaign } from '@/resources/campaign'
import type { TableHeader } from '@/resources/table'
import type { Result } from '@/resources/result'

type BaseCampaign = Pick<
  Campaign,
  | 'campaignCode'
  | 'customerCode'
  | 'customerId'
  | 'category'
  | 'isShow'
  | 'isUnlimited'
  | 'limits'
  | 'scenarioDocId'
  | 'systemInfo'
>

type CampaignType = BaseCampaign & {
  campaignId: string
  campaignName: string
  openTime: number
  closedTime: number
  startTime: number
  endTime: number
  couponNum: number
  totalTicketCount: number | null
  usedTicketCount: number | null
  isDownloading: boolean
  isProcessing: boolean
  isDynamic?: boolean
}

type ResultType = {
  userInfo: Result['userInfo'] & {
    campaignName?: string
  }
  dialogStatus: Result['dialogStatus']
  fastResult: Result['fastResult']
  systemInfo: Result['systemInfo']
}

type StatusSummaryType = {
  availableTicketCount: number
  totalTicketCount: number
  usedTicketCount: number
}

type TicketStatusSummaryType = {
  ticketStatusSummary: StatusSummaryType[]
}

type CampaignCodeToStatusSummaryType = {
  [campaignCode: string]: StatusSummaryType
}

export default defineComponent({
  name: 'HomeView',
  components: {
    // LoadingCircle,
    SetExam,
    SnackBar,
    SetSchedule,
  },
  data() {
    return {
      // 検索窓入力文字列の値指定
      filterExam: '',
      filterExamString: '',
      filterByPeriod: 'all',
      filterByPublicationPeriod: 'all',
      filterByExamPeriod: 'all',
      searchFor: 'all',
      isShowFilter: false,
      filterByPublicationPeriodString: '',
      filterByExamPeriodString: '',
      searchForString: '',
      searched: false,
      filterByOpenTime: 0,
      filterByClosedTime: 0,
      filterByStartTime: 0,
      filterByEndTime: 0,

      campaignInfo: false,

      campaignNum: 0,
      isShowCampaignNum: 0,

      isShowComponent: false,
      isLoadingCampaigns: false,
      isLoadingCoupons: false,

      // テスト情報の型指定
      campaignData: [] as CampaignType[],

      editedIndex: -1,
      editedItem: {} as CampaignType,
      snackbarActionBtn: false,
      snackbarTextField: '',
      snackbarColorField: '',
      resultList: [] as ResultType[],

      headers: [] as TableHeader[],
      options: {
        sortBy: ['openTime'],
        sortDesc: [false],
      },
    }
  },

  props: {
    snackbarAction: { type: Boolean, default: false },
    snackbarText: { type: String, default: '' },
    snackbarColor: { type: String, default: '' },
  },

  // 画面ロード時の認証とデータ取得
  async created() {
    this.initialize()
    this.updateHeaders()
  },

  async mounted() {
    await this.readData()
  },
  watch: {
    '$i18n.locale': function () {
      this.updateHeaders()
    },
  },
  methods: {
    initialize() {
      // propsの置き換え
      this.snackbarActionBtn = this.snackbarAction
      this.snackbarTextField = this.snackbarText
      this.snackbarColorField = this.snackbarColor
    },

    async resetComponent(emitedText: string) {
      this.isShowComponent = false
      this.snackbarActionBtn = true
      this.snackbarTextField = emitedText
      this.snackbarColorField = 'pink'
      await this.readData()
    },

    // テスト修正ボタン
    editItem(item: CampaignType) {
      this.editedIndex = this.campaignData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.isShowComponent = true
      // 以下画面遷移時のフィルタに関する不具合修正のための初期化
      // 急ピッチでの修正なのでもう少し良い形にしたい
      // this.filterExam = ''
      this.filterExamString = ''
      this.searchFor = ''
      this.searchForString = ''
      this.filterByExamPeriod = ''
      this.filterByExamPeriodString = ''
      this.filterByPublicationPeriod = ''
      this.filterByPublicationPeriodString = ''
    },

    // テスト削除ボタンコンポーネントで「削除」ボタンを押した際に呼び出される関数
    deleteItem() {
      this.$router.go(0)
      // 表示上からも削除
      this.campaignData.splice(this.editedIndex, 1)
      // 初期化
      this.editedIndex = -1
      this.editedItem = {
        customerCode: '',
        customerId: '',
        campaignId: '',
        campaignCode: '',
        campaignName: '',
        category: '',
        openTime: 0,
        closedTime: 0,
        startTime: 0,
        endTime: 0,
        couponNum: 0,
        totalTicketCount: 0,
        usedTicketCount: 0,
        isShow: true,
        isUnlimited: false,
        isDownloading: false,
        isProcessing: false,
        limits: 0,
        scenarioDocId: '',
        systemInfo: {
          Bacon: {
            systemVersion: '',
          },
        },
      }
      this.isShowComponent = false
      this.snackbarActionBtn = true
      this.snackbarTextField = '削除しました'
      this.snackbarColorField = 'pink'
      // 現状ではリロードするとスナックバーが一瞬で消えるので何かしらの対策が必要
    },

    // テスト期間の開始・終了日時入力用ポップアップにおける、「OK」ボタン押下時の日付入力確定機能
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.campaignData[this.editedIndex], this.editedItem)
      } else {
        this.campaignData.push(this.editedItem)
      }
    },

    searchCampaign() {
      this.isShowCampaignNum = 0 //検索の結果表示されるキャンペーンの数を初期化
      this.searched = true //検索を実行したか否か
      this.filterExamString = this.filterExam //検索語を格納（即時に反映されるのを防ぐために別の変数を使用）
      for (let i = 0; i < this.campaignData.length; i++) {
        var campaign = '' //文字列検索の対象となる文字列を格納するための変数
        this.campaignData[i]['isShow'] = true
        // 文字列検索

        if (this.searchFor === 'searchByCampaignCode') {
          campaign = this.campaignData[i]['campaignCode']
          this.searchForString = this.$t('home.campaignCode') as string
        } else if (this.searchFor === 'searchByCampaignName') {
          campaign = this.campaignData[i]['campaignName']
          this.searchForString = this.$t('home.campaignName') as string
        } else {
          campaign =
            this.campaignData[i]['campaignName'] +
            this.campaignData[i]['campaignCode']
          this.searchForString = this.$t('home.campaignCodeOrName') as string
        }
        if (campaign.indexOf(this.filterExamString) === -1) {
          this.campaignData[i]['isShow'] = false
        }

        // 現在時刻の取得
        var presentTime = new Date(moment().tz('Asia/Tokyo').format()).getTime()

        // 公開期間フィルタ
        this.filterByPublicationPeriodString = this.filterByPublicationPeriod

        if (this.filterByPublicationPeriod === 'beforePublicationPeriod') {
          if (presentTime > this.campaignData[i]['openTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'duringPublicationPeriod'
        ) {
          if (
            presentTime < this.campaignData[i]['openTime'] ||
            presentTime > this.campaignData[i]['closedTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'afterPublicationPeriod'
        ) {
          if (presentTime < this.campaignData[i]['closedTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'specifyPublicationPeriod' &&
          (this.filterByOpenTime !== 0 || this.filterByClosedTime !== 0)
        ) {
          if (
            this.filterByClosedTime < this.campaignData[i]['openTime'] ||
            this.filterByOpenTime > this.campaignData[i]['closedTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
          this.filterByPublicationPeriodString = `${this.$t(
            'home.publicationPeriodFilterOption.specifyPublicationPeriod'
          )}（${this.UnixTimeToString(
            this.filterByOpenTime
          )}) 〜 (${this.UnixTimeToString(this.filterByClosedTime)})`
        } else {
          this.filterByPublicationPeriodString = ''
        }

        // 受験期間フィルタ
        this.filterByExamPeriodString = this.filterByExamPeriod
        if (this.filterByExamPeriod === 'beforeInterviewPeriod') {
          if (presentTime > this.campaignData[i]['startTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (this.filterByExamPeriod === 'duringInterviewPeriod') {
          if (
            presentTime < this.campaignData[i]['startTime'] ||
            presentTime > this.campaignData[i]['endTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (this.filterByExamPeriod === 'afterInterviewPeriod') {
          if (presentTime < this.campaignData[i]['endTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByExamPeriod === 'specifyInterviewPeriod' &&
          (this.filterByStartTime !== 0 || this.filterByEndTime !== 0)
        ) {
          if (
            this.filterByEndTime < this.campaignData[i]['startTime'] ||
            this.filterByStartTime > this.campaignData[i]['endTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
          this.filterByExamPeriodString = `${this.$t(
            'home.examinationPeriodFilterOption.specifyInterviewPeriod'
          )} (${this.UnixTimeToString(
            this.filterByStartTime
          )}) 〜 (${this.UnixTimeToString(this.filterByEndTime)})`
        } else {
          this.filterByExamPeriodString = ''
        }

        // 表示数のカウント
        if (this.campaignData[i]['isShow']) {
          this.isShowCampaignNum++
        }
      }
    },

    // DBからの読み込み
    async readData() {
      this.campaignData = []
      this.campaignNum = 0
      this.isShowCampaignNum = 0
      const campaignsRef = collection(db, 'campaigns')
      const customerId = store.state.customerId

      this.isLoadingCampaigns = true
      this.isLoadingCoupons = true

      const campaignsData = await getDocs(
        query(campaignsRef, where('customerId', '==', customerId))
      )
      for (const document of campaignsData.docs) {
        const campaignId = document.id
        if (document.data().couponCount === undefined) {
          var couponNum = (
            await getDocs(collection(db, 'campaigns', campaignId, 'coupons'))
          ).size
          await updateDoc(doc(db, 'campaigns', campaignId), {
            couponCount: couponNum,
          })
        } else {
          // eslint-disable-next-line no-redeclare
          var couponNum = document.data().couponCount as number
        }

        if (!document.data().isDeleted) {
          this.campaignNum++
          this.isShowCampaignNum++

          this.campaignData.push({
            customerId: document.data().customerId,
            customerCode: document.data().customerCode,
            campaignId: campaignId,
            campaignCode: document.data().campaignCode,
            campaignName: document.data().name,
            category: document.data().category,
            openTime: this.Utc2UnixTime(document.data().openAt),
            closedTime: this.Utc2UnixTime(document.data().closedAt),
            startTime: this.Utc2UnixTime(document.data().startAt),
            endTime: this.Utc2UnixTime(document.data().endAt),
            couponNum: couponNum,
            totalTicketCount: null,
            usedTicketCount: null,
            isShow: true,
            isUnlimited: document.data().isUnlimited,
            limits: document.data().limits,
            systemInfo: document.data().systemInfo,
            isDownloading: false,
            isProcessing: false,
            scenarioDocId: document.data().scenarioDocId,
            isDynamic: document.data().isDynamic,
          })
        }
        // console.log(document.id)
      }
      this.isLoadingCampaigns = false

      // cloud functionを使ってクーポン登録者数と受験終了者数を取得しにいく
      let campaignCodeToStatusSummaryType =
        {} as CampaignCodeToStatusSummaryType
      try {
        const getTicketStatusSummary = httpsCallable(
          functions,
          'getTicketStatusSummary'
        )
        const inputData = {
          campaignCodes: campaignsData.docs.map(
            (doc) => doc.data().campaignCode
          ),
        }
        const result = await getTicketStatusSummary(inputData)
        const data = result.data as TicketStatusSummaryType
        for (let campaignCode in data.ticketStatusSummary) {
          campaignCodeToStatusSummaryType[campaignCode] =
            data.ticketStatusSummary[campaignCode]
        }
        for (let i = 0; i < this.campaignData.length; i++) {
          const campaign = this.campaignData[i]
          const campaignCode = campaign.campaignCode
          campaign.totalTicketCount =
            campaignCodeToStatusSummaryType[campaignCode].totalTicketCount
          campaign.usedTicketCount =
            campaignCodeToStatusSummaryType[campaignCode].usedTicketCount
        }
      } catch (error: any) {
        const code = error.code
        // const message = error.message
        // const details = error.details
        console.log('Cloud Function err code: ', code)
        // console.log('Cloud Function err message: ', message)
        // console.log('Cloud Function err details: ', details)
      } finally {
        this.isLoadingCoupons = false
      }
    },

    // UnixTime変換
    Utc2UnixTime(dateString: string) {
      // timezoneをZ(UTC)と指定してunixTimeを取得
      return new Date(dateString).getTime()
    },

    UnixTimeToString(UnixTime: number) {
      return moment(UnixTime).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
    },

    async getResultsByCampaign(campaignId: string) {
      // cloud functionsとの連携
      const getResultsByCampaignIdForManagers = httpsCallable(
        functions,
        'getResultsByCampaignIdForManagers'
      )
      const inputData = {
        campaignId: campaignId,
      }
      return await getResultsByCampaignIdForManagers(inputData)
        .then((result) => {
          const data = result.data
          // console.log(data)
          return data
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },

    async getResultsCountByCampaignId(campaignId: string) {
      //resultListのリセット
      this.resultList = [] as ResultType[]
      const getResultsByCampaignIdForManagers = httpsCallable(
        functions,
        'getResultsByCampaignIdForManagers'
      )
      const inputData = {
        campaignId: campaignId,
      }
      return await getResultsByCampaignIdForManagers(inputData)
        .then((result) => {
          this.resultList = result.data as ResultType[]

          // 当該examのユニーク受験者数をカウントしてリターン
          // 再受験は一切考慮しない（一回でも受験していれば受験済にカウント：再受験を詳細に扱う場合はdocument/propertyやfunctionの追加が実行性能的に妥当と考えられる
          let studentIds = [] as string[]
          let resultsCount = 0
          this.resultList.forEach((target) => {
            if (target.userInfo.couponCode) {
              if (!studentIds.includes(target.userInfo.couponCode)) {
                resultsCount++
                studentIds.push(target.userInfo.couponCode)
              }
            }
          })
          return resultsCount
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },

    // DLボタン押下時の処理
    async downloadBtnAction(campaignId: string) {
      for await (const item of this.campaignData) {
        if (item.campaignId === campaignId) {
          if (item.isProcessing) {
            break
          } else {
            item.isDownloading = true
            item.isProcessing = true
            await this.downloadData(campaignId).then(() => {
              setTimeout(() => {
                item.isDownloading = false
                item.isProcessing = false
              }, 500)
            })
          }
          break
        }
      }
    },
    // csv変換を加えたDL機能
    async downloadData(campaignId: string) {
      // console.log('running data export...')
      let jsonData = {} as ResultType[]
      jsonData = await this.downloadCampaignData(campaignId)
      if (jsonData) {
        this.downloadForCsv(jsonData)
      } else {
        this.downloadForNullCsv()
      }
    },

    async downloadCampaignData(campaignId: string) {
      // console.log('download campaignId:', campaignId)
      const result = (await this.getResultsByCampaign(
        campaignId
      )) as ResultType[]
      // console.log('JSON data: ', result)
      return result
    },

    downloadForCsv(jsonData: ResultType[]) {
      const env = process.env.NODE_ENV
      let langxWebAppUrl =
        env === 'development' && window.location.hostname === 'localhost'
          ? 'http://localhost:5173'
          : env === 'development' && window.location.hostname !== 'localhost'
          ? 'https://develop-langx-learner-webapp-2aru7nyvta8n.web.app'
          : env === 'staging'
          ? 'https://staging-langx-learner-webapp-t2qbajc92vch.web.app'
          : env === 'production'
          ? 'https://speaking.langx.ai'
          : 'http://localhost:5173'

      var dataCsv =
        'CampaignCode,CouponCode,Completion time,CEFR Level,CEFR Score,Accuracy Level,Accuracy Score,Coherence Level,Coherence Score,Fluency Level,Fluency Score,Interaction Level,Interaction Score,Phonology Level,Phonology Score,Range Level,Range Score,channelName,Link to shared report page' //ここの内容は何が必要か要確認
      jsonData.forEach((data) => {
        const interactionLevel =
          data.fastResult.score.interaction.score === -1
            ? this.$t('conversationAnalysis.unrated')
            : data.fastResult.score.interaction.cefrLevel
        const interactionScore =
          data.fastResult.score.interaction.score === -1
            ? ''
            : data.fastResult.score.interaction.score

        let csvCampaignCode = data.userInfo.campaignCode
        // let csvCampaignName = data.userInfo.campaignName
        let csvCouponCode = data.userInfo.couponCode
        // eslint-disable-next-line
        let csvEndAt = data.dialogStatus.assessmentCompletedAt
        let csvEndAtJST = this.UnixTimeToString(this.Utc2UnixTime(csvEndAt))
        let csvCefrLevel = data.fastResult.score.cefrOverall.cefrLevel
        let csvCefrScore = data.fastResult.score.cefrOverall.score
        let csvAccuracyLevel = data.fastResult.score.accuracy.cefrLevel
        let csvAccuracyScore = data.fastResult.score.accuracy.score
        let csvCoherenceLevel = data.fastResult.score.coherence.cefrLevel
        let csvCoherenceScore = data.fastResult.score.coherence.score
        let csvFluencyLevel = data.fastResult.score.fluency.cefrLevel
        let csvFluencyScore = data.fastResult.score.fluency.score
        let csvInteractionLevel = interactionLevel
        let csvInteractionScore = interactionScore
        let csvPhonologyLevel = data.fastResult.score.phonology.cefrLevel
        let csvPhonologyScore = data.fastResult.score.phonology.score
        let csvRangeLevel = data.fastResult.score.range.cefrLevel
        let csvRangeScore = data.fastResult.score.range.score
        let csvChannelName = data.dialogStatus.channelName
        let sharedReportLink = `${langxWebAppUrl}/shared-report/${data.userInfo.uid}_${data.dialogStatus.channelName}`
        var dataLine =
          '\n' +
          csvCampaignCode +
          ',' +
          // csvCampaignName +
          // ',' +
          csvCouponCode +
          ',' +
          csvEndAtJST +
          ',' +
          csvCefrLevel +
          ',' +
          csvCefrScore +
          ',' +
          csvAccuracyLevel +
          ',' +
          csvAccuracyScore +
          ',' +
          csvCoherenceLevel +
          ',' +
          csvCoherenceScore +
          ',' +
          csvFluencyLevel +
          ',' +
          csvFluencyScore +
          ',' +
          csvInteractionLevel +
          ',' +
          csvInteractionScore +
          ',' +
          csvPhonologyLevel +
          ',' +
          csvPhonologyScore +
          ',' +
          csvRangeLevel +
          ',' +
          csvRangeScore +
          ',' +
          csvChannelName +
          ',' +
          sharedReportLink
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        dataCsv += dataLine
      })
      const blob = new Blob([`\uFEFF${dataCsv}`], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      let now = new Date(moment().tz('Asia/Tokyo').format())
      // eslint-disable-next-line
      // link.download = 'exportData' + '-' + sectionId.substring(0, 4) + '-' +displayExamId + now.getTime() + '.csv'
      link.download = 'exportData_' + now.getTime() + '.csv'
      link.click()
      link.remove()
    },

    downloadForNullCsv() {
      var str = 'No Results'
      const blob = new Blob([str], {
        type: 'text/csv',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      let now = new Date(moment().tz('Asia/Tokyo').format())
      // eslint-disable-next-line
      // link.download = 'exportData' + '-' + sectionId.substring(0, 4) + '-' +displayExamId + now.getTime() + '.csv'
      link.download = 'exportData_' + now.getTime() + '.csv'
      link.click()
      link.remove()
    },

    closeSnackbar() {
      this.isSnackbarActionBtn = false
    },

    getHeaders(): TableHeader[] {
      return [
        {
          text: this.$t('home.tableHeader.campaignCodeOrName') as string,
          value: 'campaignCode',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('home.tableHeader.publicPeriod') as string,
          value: 'openTime',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('home.tableHeader.examPeriod') as string,
          value: 'startTime',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('home.tableHeader.couponCounts') as string,
          value: 'couponCounts',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('home.tableHeader.interviewCountPerCoupon') as string,
          value: 'interviewCountPerCoupon',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('home.tableHeader.edit') as string,
          value: 'edit',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('home.tableHeader.showResults') as string,
          value: 'showResults',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('home.tableHeader.download') as string,
          value: 'download',
          align: 'center',
          sortable: false,
        },
      ]
    },

    updateHeaders() {
      this.headers = this.getHeaders()
    },
  },
})
