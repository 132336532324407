import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","width":"80%"},model:{value:(_vm.isLoadingField),callback:function ($$v) {_vm.isLoadingField=$$v},expression:"isLoadingField"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v("Loading...")]),_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1),_c(VCardText,{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.textField)+" ")]),_c('div',[(_vm.progressValueField !== 0)?_c(VProgressLinear,{attrs:{"color":"primary","height":"15","min":0,"max":100},model:{value:(_vm.progressValueField),callback:function ($$v) {_vm.progressValueField=$$v},expression:"progressValueField"}},[_vm._v(_vm._s(_vm.progressValueField)+" % ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }