import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"full-height-width-card align-center justify-center",attrs:{"color":"transparent","flat":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VCard,{staticClass:"ma-10 card_shadow align-center justify-center"},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""}},[_c(VContainer,{staticClass:"pa-13"},[_c(VRow,{attrs:{"justify":"center"}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('common.langxConsole')))])]),_c(VRow,{staticClass:"mt-7",attrs:{"justify":"center"}},[_c('p',[_vm._v(_vm._s(_vm.$t('signUp.emailApprovalCompletedMessage')))])]),_c(VRow,{staticClass:"mt-n7",attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"mt-4 under-line",attrs:{"text":"","color":"blue"},on:{"click":_vm.goToSignIn}},[_vm._v(" "+_vm._s(_vm.$t('signUp.signInHereButton'))+" ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }