
import { defineComponent } from '@vue/composition-api'
import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth'
import { auth } from '@/plugins/firebase'

export default defineComponent({
  name: 'VerifiedView',
  data() {
    return {}
  },

  mounted() {
    const mode = this.$route.query.mode as string
    const oobCode = this.$route.query.oobCode as string
    // console.log(this.$route.query.mode)
    // console.log(this.$route.query.oobCode)
    if (auth && oobCode) {
      mode === 'verifyEmail'
        ? applyActionCode(auth, oobCode)
        : verifyPasswordResetCode(auth, oobCode)
    }
  },

  methods: {
    goToSignIn() {
      this.$router.push({ name: 'Sign In' })
    },
  },
})
