import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

// .env.* を参照し接続先firebaseプロジェクトの設定を取得
// localhostのエミュレータ利用の場合は末尾の条件分岐によって自動接続
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}

export const firebaseApp = initializeApp(firebaseConfig)

const db = getFirestore(firebaseApp)
const database = getDatabase(firebaseApp)
const auth = getAuth(firebaseApp)
const functions = getFunctions(firebaseApp)
functions.region = 'asia-northeast1'

if (location.hostname === 'localhost') {
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectDatabaseEmulator(database, 'localhost', 9000)
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export { db, database, auth, functions }
