
import { database, functions } from '@/plugins/firebase'
import { defineComponent } from '@vue/composition-api'
import { ref, get } from 'firebase/database'
import { httpsCallable } from 'firebase/functions'
import type { Result } from '@/resources/result'
import {
  cefrStyleClass,
  roundToTwoDecimals,
} from '@/components/utils/commonUtils'
import type { CefrTableBody, TranscriptTableBody } from '@/resources/table'
import store from '@/store'
import CardHeader from '@/components/Common/CardHeader.vue'

export default defineComponent({
  components: {
    CardHeader,
  },
  name: 'ConversationAnalysis',
  data() {
    return {
      loading: true,
      couponCode: '',
      dialogId: this.$route.params.dialogId,
      userId: '',
      cefrTableHeaders: [] as string[],
      cefrTableBody: [] as CefrTableBody[],
      transcriptTableHeaders: [] as string[],
      transcriptTableBody: [] as TranscriptTableBody[],
      error: false,
      results: null as Result | null,
    }
  },
  watch: {
    '$i18n.locale'() {
      this.updateHeaders()
      if (this.results) {
        this.populateCefrTable(this.results)
      }
    },
  },
  created() {
    this.updateHeaders()
  },
  async mounted() {
    const customerId = store.state.customerId

    try {
      const results = await this.fetchCampaignDataByChannelName(this.dialogId)

      if (!results || results.userInfo.customerId !== customerId) {
        this.error = true
        return
      }

      this.couponCode = results.userInfo.couponCode
      this.userId = results.userInfo.uid
      this.results = results

      this.populateCefrTable(results)

      await this.fetchTranscripts()
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  },
  computed: {
    formattedCaptionText() {
      const rawText = this.$t('conversationAnalysis.captionText').toString()
      const parts = rawText.split('*')
      return `
      <span class="font-italic">${parts[0]}</span>
      ${parts[1]}
      <span class="font-weight-bold">${parts[2]}</span>
      ${parts[3]}
    `
    },
  },
  methods: {
    cefrStyleClass,
    /**
     * Updates the table headers for the CEFR Table.
     */
    updateHeaders() {
      this.cefrTableHeaders = [
        this.$t('conversationAnalysis.cefrTableHeaders.overall') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.range') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.accuracy') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.phonology') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.fluency') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.coherence') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.interaction') as string,
      ]
      this.transcriptTableHeaders = [
        this.$t('conversationAnalysis.transcriptTableHeaders.topic') as string,
        this.$t('conversationAnalysis.transcriptTableHeaders.turnId') as string,
        this.$t(
          'conversationAnalysis.transcriptTableHeaders.startTime'
        ) as string,
        this.$t(
          'conversationAnalysis.transcriptTableHeaders.endTime'
        ) as string,
        this.$t(
          'conversationAnalysis.transcriptTableHeaders.utteranceText'
        ) as string,
      ]
    },
    /**
     * Populates the CEFR (Common European Framework of Reference) table with scores.
     */
    populateCefrTable(scores: Result) {
      this.cefrTableBody = [
        {
          label: scores.fastResult.score.cefrOverall.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.cefrOverall.score
          ),
        },
        {
          label: scores.fastResult.score.range.cefrLevel,
          value: this.roundToTwoDecimals(scores.fastResult.score.range.score),
        },
        {
          label: scores.fastResult.score.accuracy.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.accuracy.score
          ),
        },
        {
          label: scores.fastResult.score.phonology.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.phonology.score
          ),
        },
        {
          label: scores.fastResult.score.fluency.cefrLevel,
          value: this.roundToTwoDecimals(scores.fastResult.score.fluency.score),
        },
        {
          label: scores.fastResult.score.coherence.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.coherence.score
          ),
        },
        {
          label:
            scores.fastResult.score.interaction.score === -1
              ? (this.$t('conversationAnalysis.unrated') as string)
              : scores.fastResult.score.interaction.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.interaction.score
          ),
        },
      ]
    },
    /**
     * Fetches campaign data for a given channel name.
     * This function calls a cloud function 'getResultsByChannelName'
     * to retrieve the campaign data associated with the specified channel name.
     *
     * @param {string} dialogId - The unique identifier for the channel.
     * @returns {Promise<Result>} The campaign data as a Result object.
     */
    async fetchCampaignDataByChannelName(dialogId: string) {
      const getResultsByChannelName = httpsCallable(
        functions,
        'getResultsByChannelName'
      )
      const response = await getResultsByChannelName({
        channelName: dialogId,
      })

      return response.data as Result
    },
    /**
     * Fetches and processes the transcript data for a specific dialog.
     *
     * This function retrieves the transcript data from the Firebase Realtime Database
     * based on the `userId` and `dialogId` properties. It then processes the data
     * to create a structured array for rendering in a table. Each row in the table
     * corresponds to a line in the transcript, with additional processing to group
     * and merge rows by topic.
     */
    async fetchTranscripts() {
      const path = `gnowsisResults/transcripts/${this.userId}_${this.dialogId}`

      const snapshot = await get(ref(database, path))
      if (snapshot.exists()) {
        const transcriptData = snapshot.val()

        const rows = transcriptData.trim().split('\n').slice(1)

        const topics = new Map<string, number>()
        let currentTopicIndex = 0

        this.transcriptTableBody = rows.map((row: string, index: number) => {
          const [startTime, endTime, topic, speaker, text] = row.split('\t')
          const icon = speaker === 'system' ? 'mdi-robot' : 'mdi-account'
          let isFirstInTopic = false
          let rowSpan = 1

          if (!topics.has(topic)) {
            currentTopicIndex++
            topics.set(topic, currentTopicIndex)
            isFirstInTopic = true

            rowSpan = rows.filter((r: string) => r.includes(topic)).length
          }

          return {
            topic: `Topic-${topics.get(topic)?.toString().padStart(2, '0')}`,
            turnId: (index + 1).toString().padStart(3, '0'),
            startTime,
            endTime,
            icon,
            text,
            isFirstInTopic,
            rowSpan,
          }
        })
      } else {
        console.error('No data available')
      }
    },
    roundToTwoDecimals,
  },
})
