import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"ma-10 card_shadow",attrs:{"max-width":"484px"}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,{staticClass:"ml-5 pt-5"},[_c(VRow,[_c('h2',[_vm._v(_vm._s(_vm.$t('securitySettings.currentPasswordPrompt')))])]),_c(VRow,[_c(VCol,{attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":_vm.$t('common.password'),"persistent-placeholder":"","outlined":"","required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VBtn,{staticClass:"white--text",attrs:{"color":"#1867C0"},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.$t('securitySettings.next'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }