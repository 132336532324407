import dayjs from 'dayjs'

// Convert UTC to JST
export const getJstTime = (date: string) => {
  return dayjs.utc(date).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
}

// Get two JST dates and calculate end - start
export const calculateDuration = (start: string, end: string): string => {
  const startDate = dayjs(start)
  const endDate = dayjs(end)
  const diffMs = endDate.diff(startDate)
  const diffHrs = Math.floor(diffMs / 3600000)
  const diffMins = Math.floor((diffMs % 3600000) / 60000)
  const diffSecs = Math.floor((diffMs % 60000) / 1000)

  return `${String(diffHrs).padStart(2, '0')}:${String(diffMins).padStart(
    2,
    '0'
  )}:${String(diffSecs).padStart(2, '0')}`
}
