import VueI18n from 'vue-i18n'
import Vue from 'vue'
import ja from './ja.json'
import en from './en.json'

Vue.use(VueI18n)

const messages = {
  ja: ja,
  en: en,
}

export const i18n = new VueI18n({
  locale: window.localStorage.getItem('language') || 'ja',
  messages,
})
