
import { defineComponent } from '@vue/composition-api'
import { updatePassword } from 'firebase/auth'
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth'
import InputNewPass from '@/components/Dashboard/PageViewItems/InputNewPasswordField.vue'

export default defineComponent({
  name: 'SecurityPage',
  components: {
    InputNewPass,
  },
  setup() {
    const auth = getAuth()
    const user = auth.currentUser
    let email = ''
    if (user && user.email) {
      email = user.email
    }
    return {
      user,
      email,
    }
  },
  data() {
    return {
      password: '',
      isDisable: true,
      showPassword: false,
      show: false,
      isSignInError: false,
    }
  },
  methods: {
    onClick() {
      if (this.user != null) {
        updatePassword(this.user, this.password).then(() => {
          // console.log('パスワードの変更に成功しました')
          this.$router.push({
            name: 'Sign In',
            params: {
              snackbarAction: 'true',
              snackbarText: 'パスワードを変更しました',
              snackbarColor: 'pink',
            },
          })
        })
      }
    },

    verifyPassword() {
      const auth = getAuth()
      signInWithEmailAndPassword(auth, this.email, this.password)
        // .then((user) => {
        .then(() => {
          // console.log('認証に成功しました: ', user.user.email)
          this.show = true
        })
        .catch((error) => {
          console.log('error code:', error.code)
          // リリース前にconsole表示は削除
          // const errorCode = error.code
          // const errorMessage = error.message
          // console.log(
          //   'Firebase Authenticationからエラーが返されました: ',
          //   errorCode,
          //   ', ',
          //   errorMessage
          // )
          this.isSignInError = true
        })
    },

    // コンポーネントからの値の受け取り
    setPassword(pass: string) {
      this.password = pass
    },
    disableBtn(isDisable: boolean) {
      this.isDisable = isDisable
    },
  },
})
